import logo from './logo.svg';
import './App.css';
import React from 'react';
import Fetch from './Fetch';;

class App extends React.Component {
  state = { result: null };

    toggleButtonState = () => {
    let selectedWord = window.getSelection().toString();
    let firstName = 'erica'
    let lastName = 'Oniel'
    alert(selectedWord)
    try {
     
      fetch('https://rv20ipyb5d.execute-api.us-east-1.amazonaws.com/dev', {method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"firstName":firstName,"lastName":lastName})})
          .then(data => data.json()) // Parsing the data into a JavaScript object
          .then(json => alert(JSON.stringify(json))) // Displaying the stringified data in an alert popup
    } catch (error) {
      console.error('Error:', error);
    }
  };

  Data = () => { 
    
    const fetchData = async (firstName,lastName) => {
      
    }
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <button onClick={this.toggleButtonState}> Click me </button>
        <div>{this.state.result}</div>
        
      </div>
    );
  }
}

export default App;
